import jQuery from 'jquery'
import { csvExport } from 'src/dataGrid/common'

const CONTAINER_ID = 'e-toolbar-right'
const ACTION_MENU_ID = 'events-grid-action-menu'
const CSV_EXPORT_ID = 'data-grid-csv-export'

/**
 * A class for rendering actions that can be taken against a Events Grid.
 */
export class EventsGridActionMenu {
  /**
   * Create a EventsGridActionMenu
   * @param {Grid} grid - The grid the menu is inserting itself into
   */
  constructor(grid) {
    this.grid = grid
    this.container = document.querySelector(`.${CONTAINER_ID}`)
  }

  /**
   * Cleans up an existing events and dom elements and renders them
   */
  render() {
    if(this.menuWrapper) {
      this.cleanupEvents()
      this.container.removeChild(this.menuWrapper)
    }

    this.menuWrapper = document.createElement('div')
    this.menuWrapper.className = 'e-toolbar-item grid-actions'
    this.menuWrapper.appendChild(this.buildMenuButton())
    this.menuWrapper.appendChild(this.buildMenu())

    this.container.appendChild(this.menuWrapper)
    jQuery(this.container).foundation()
    this.assignEvents()
  }

  /**
   * Builds and appends the action menu button
   */
  buildMenuButton() {
    let button = document.createElement('button')
    button.type='button'
    button.className = 'button action-menu standalone'
    button.dataset.toggle = ACTION_MENU_ID

    let icon = document.createElement('span')
    icon.className = 'ellipsis-icon'
    icon.setAttribute('test_id', 'action-menu')

    button.appendChild(icon)

    return button
  }

  /**
   * Builds a dropdown pane that is in a state to be leveraged by foundation.
   */
  buildMenu() {
    let menu = document.createElement('div')
    menu.className = 'dropdown-pane'
    menu.id = ACTION_MENU_ID
    menu.dataset.closeOnClick = 'true'
    menu.dataset.autoFocus = 'true'
    menu.dataset.dropdown = 'true'

    menu.appendChild(this.buildExportCSVButton())

    return menu
  }

  /**
   * Builds a csv export button for dropdown menu
   */
  buildExportCSVButton() {
    let elem = document.createElement('a')
    elem.innerHTML = 'Export to CSV'
    elem.setAttribute('test_id', 'export-to-csv')
    elem.dataset.role = CSV_EXPORT_ID

    return elem
  }

  /**
   * callback handler for event listener on csv export button
   */
  handleCsvExport() {
    csvExport(this.grid)
  }

  /**
   * assigns event listeners to drop down menu items
   */
  assignEvents() {
    this
      .container
      .querySelector(`[data-role='${CSV_EXPORT_ID}']`)
      .addEventListener('click', this.handleCsvExport.bind(this))
  }

  /**
   * removes event listeners on drop down menu items
   */
  cleanupEvents() {
    this
      .container
      .querySelector(`[data-role='${CSV_EXPORT_ID}']`)
      .removeEventListener('click', this.handleCsvExport.bind(this))
  }
}
