import { beforeTurboCache } from 'src/turbo'
import { BLGrid,
         defaultGridOptions,
         columnsFromTable,
         pluckTableData } from 'src/dataGrid/common'

const gridId = 'users-datagrid'
const gridOptions = {
  toolbar: [{ text: 'Search', align: 'left' }],
  dataBound: () => {
    Foundation.reInit($('.syncfusion-foundation-grid')) // This toggle elements off
    Foundation.reInit($('.syncfusion-foundation-grid')) // This toggle elements on
  }
}

export const usersGrid = {
  id: gridId,
  load: () => {
    const data = pluckTableData('#grid-fallback-table')
    const grid = new BLGrid({
      ...defaultGridOptions,
      ...gridOptions,
      columns: columnsFromTable(false),
      dataSource: data
    });

    beforeTurboCache(() => grid.destroy())
    grid.appendTo(`#${gridId}`)
  }
}
