import { ExcelExport } from '@syncfusion/ej2-grids'
import { beforeTurboCache } from 'src/turbo'
import { BLGrid,
         defaultGridOptions,
         columnsFromTable,
         getGrid,
         pluckTableData,
         csvExport } from 'src/dataGrid/common'

BLGrid.Inject(ExcelExport)

const gridId = 'evidence-artifacts-datagrid'
const gridOptions = {
  toolbar: [
    { text: 'Search', align: 'left' },
    { text: 'CSV Export', id: 'data-grid-csvexport', align: 'right' }
  ],
  toolbarClick: (args) => {
    const grid = getGrid(gridId)
    if (args.item.id === 'projects-datagrid_search') { grid.columns.forEach(c => c.exporting = false) }
    if (args.item.id === 'data-grid-csvexport') { csvExport(grid) }
  },
}

export const evidenceArtifactsGrid = {
  id: gridId,
  load: () => {
    const data = pluckTableData('#grid-fallback-table')
    const gridElement = document.getElementById(gridId)
    const sortBy = gridElement.dataset.sortBy
    const sortDirection = gridElement.dataset.sortDirection
    const grid = new BLGrid({
      ...defaultGridOptions,
      ...gridOptions,
      columns: columnsFromTable(false),
      dataSource: data,
      sortSettings: { columns: [{ field: sortBy, direction: sortDirection }] }
    });

    beforeTurboCache(() => grid.destroy())

    grid.appendTo(`#${gridId}`)

    if (gridElement.dataset.filterTerm) {
      grid.filterByColumn(gridElement.dataset.filterField, 'equal', gridElement.dataset.filterTerm, 'and', true)
    }
  }
}
