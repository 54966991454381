import jQuery from 'jquery'
import { extractIdFromLink } from 'src/dataGrid/common'

export class ProjectsForm {
  constructor() {
    this.form = document.getElementById('project-form')
    this.project_name_input = document.getElementById('project_name')
    this.project_select_input = document.getElementById('project_id')
    this.findingsList = document.getElementById('findings-list')
    this.associatedFindingsMessage = document.getElementById('associated-findings-message')
    this.noFindingsMessage = document.getElementById('no-findings-message')
    this.submitButton = document.getElementById('submit-project-form')
    this.addFormTargetListener()
  }

  appendFindingsToForm(findings) {
    this.cleanupInputs()

    var findingsWithProject = findings.filter(f => f.project_name != 'N/A' ).length > 0

    if (findingsWithProject) {
      this.associatedFindingsMessage.classList.remove("hidden")
    }

    if (findings.length == 0) {
      this.noFindingsMessage.classList.remove("hidden")
    }

    findings.forEach(f => {
      const id = extractIdFromLink(f.id)
      const input = `
        <label class="custom-checkbox-container"><a href="/reports/unmet_requirements/${id}/edit">${id}</a> - ${f.name}
          <p class="text-grey">Project: ${f.project_name}</p>
          <input type="checkbox" name="finding_ids[]" id="finding_ids_" value="${id}" class="project-checkboxes custom-check-option" checked>
          <span class="checkmark"></span>
        </label>
      `
      this.findingsList.insertAdjacentHTML('afterbegin', input)
    })

    jQuery('#add-to-project').foundation('toggle')
  }

  addFormTargetListener() {
    if (this.form == undefined) return

    var currentScope = this
    document.querySelectorAll('input[name="project_selection"]').forEach((elem) => {
      elem.addEventListener("change", function(event) {
        var item = event.target.value
        currentScope.project_name_input.value = ""
        currentScope.project_select_input.selectedIndex = null;
        if (item === 'New Project') {
          currentScope.project_name_input.disabled = false
          currentScope.project_name_input.classList.remove("hidden")
          currentScope.project_select_input.disabled = true
          currentScope.project_select_input.classList.add("hidden")
          currentScope.submitButton.disabled = true
        } else {
          currentScope.project_name_input.disabled = true
          currentScope.project_name_input.classList.add("hidden")
          currentScope.project_select_input.disabled = false
          currentScope.project_select_input.classList.remove("hidden")
          currentScope.submitButton.disabled = false
        }
      })
    })

    this.project_name_input.addEventListener("input", function(event) {
      var value = event.target.value
      if (value === '') {
        currentScope.submitButton.disabled = true
      } else {
        currentScope.submitButton.disabled = false
      }
    })
  }

  cleanupInputs() {
    this.findingsList.innerHTML = ''
    // Reset fields
    document.querySelectorAll('input[name="project_selection"]')[0].checked = true
    document.querySelectorAll('input[name="project_selection"]')[1].checked = false
    this.project_name_input.disabled = false
    this.project_name_input.classList.remove("hidden")
    this.project_name_input.value = ""
    this.project_select_input.disabled = true
    this.project_select_input.classList.add("hidden")
    this.project_select_input.selectedIndex = null
    this.associatedFindingsMessage.classList.add("hidden")
    this.noFindingsMessage.classList.add("hidden")
    this.submitButton.disabled = true
  }
}
