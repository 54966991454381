import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { viewsGrouping, gridId, updateSelectedFindingsCount } from "./grids/findings";
import { getGrid } from "./common";

const TITLES_MAPPING = {
  default: 'Common Themes View',
  maturity: 'Maturity View',
  capabilities: 'Capabilities View',
  risks: 'Risks Priority View',
}

const VIEW_OPTIONS = [
  { displayName: 'Common Themes View', value: 'default' },
  { displayName: 'Maturity View', value: 'maturity' },
  { displayName: 'Capabilities View', value: 'capabilities' },
]

const VIEW_OPTIONS_WITH_RISK = VIEW_OPTIONS.concat(
  [{ displayName: 'Risks Priority View', value: 'risks' }]
)

export class FindingsGridViewsDropdown {

  constructor(containerId, currentView, risksEnabled) {
    this.containerId = containerId
    this.currentView = currentView || 'default'
    this.risksEnabled = risksEnabled
    this.dropdown = null
  }

  render() {
    this.dropdown = new DropDownList({
      created: () => { this.dropdown.text = TITLES_MAPPING[this.currentView] },
      select: (event) => {
        Object.entries(viewsGrouping).forEach(([k, v]) => {
          let grid = getGrid(gridId);

          // Conditionally render the risks dropdown only for the risks view.
          let riskDropDownWrapper = document.getElementsByClassName('e-toolbar-item')[1]
          if (event.itemData.value === 'risks') {
            riskDropDownWrapper.classList.remove('hidden')
          } else {
            riskDropDownWrapper.classList.add('hidden')
          }

          // When changing from the risk view, reload the page to ensure we aren't
          // scoping the finding data to a particular risk anymore.
          if (this.currentView === 'risks' && event.itemData.value !== 'risks') {
            const url = new URL(document.location.href)
            url.searchParams.set('view', event.itemData.value)
            url.searchParams.delete('risk_code')
            window.location.replace(url)
          }

          if (event.itemData.value === k) {
            // If we go from group to group or ungrouped, clear the selections
            grid.clearSelection();
            updateSelectedFindingsCount(grid);
            grid.clearGrouping();

            // Start at 100 to allow for group clearing to happen
            let delay = 100;
            v.forEach(col => {
              // I hate this delay solution, but syncfusion has a race condition when adding grouped columns via their group api
              setTimeout(() => {
                grid.groupColumn(col)
              }, delay);

              delay += 150 // 150ms was achieved by trial and error locally
            })
          }
        })
      },
      dataSource: this.risksEnabled ? VIEW_OPTIONS_WITH_RISK : VIEW_OPTIONS,
      showClearButton: true,
      fields: { text: 'displayName', value: 'value' },
      placeholder: 'Select View',
      cssClass: 'findings-view-dropdown'
    })

    this.dropdown.appendTo(`#${this.containerId}`)
  }

  reset() {
    this.dropdown.clear();
  }
}
