import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  answer(singleChoiceController, removeOverlayController, childQuestion, index, timeOutinit, timeOutmult, external, selectedBooleanValue, questionOptionText) {
    setTimeout(() => {
      let hasOverlayActive = true
      document.body.querySelectorAll("div[data-external='" + external + "'] .choices .choice a.selected").forEach((choice) => {
        if (choice.classList.contains('selected')) {
          let hrefAttributes = choice.getAttribute('href').split("=");
          let selectedOptionValue = hrefAttributes[1];
          if (selectedOptionValue === selectedBooleanValue) {
            childQuestion.parentElement.classList.add('active')
            singleChoiceController._singleChoice(childQuestion, questionOptionText)
          }
          else {
            if (childQuestion.dataset.value !== questionOptionText) {
              childQuestion.parentElement.classList.add('active')
              singleChoiceController._singleChoice(childQuestion, questionOptionText)
            }
            else {
              hasOverlayActive = childQuestion.parentElement.classList.contains('active');
              if (hasOverlayActive) {
                childQuestion.parentElement.classList.remove('active')
                removeOverlayController._removeQuestionOverlay(childQuestion)
                singleChoiceController._singleChoice(childQuestion, questionOptionText)
              }
            }
          }
        }
      })
    }, (index == 0) ? timeOutinit : timeOutinit + (index * timeOutmult));
  }
}
