import {Controller} from '@hotwired/stimulus';

const Chart = require('chart.js'); // Using chart.js 2.9.4 from package.json (normally 3.x would be used)

// Shamelessly adapted from https://www.stimulus-components.com/docs/stimulus-chartjs/ and adapted to be compatible
// with chart.js 2.9.4 instead of 3.x which it normally would use.
export default class extends Controller {
    static targets = ['canvas'];
    static  values = {
        type: {
            type: String,
            default: 'line'
        },
        data: Object,
        options: Object
    };

    connect() {
        this.drawCenterText();
        const element = this.hasCanvasTarget ? this.canvasTarget : this.element;
        this.chart = new Chart(element.getContext('2d'), {
            type: this.typeValue,
            data: this.chartData,
            options: this.chartOptions
        });
        this.chart.generateLegend();
    }

    disconnect() {
        this.chart.destroy();
        this.chart = undefined;
    }

    get chartData() {
        if (!this.hasDataValue) {
            console.warn('[stimulus-chartjs] You need to pass data as JSON to see the chart.');
        }
        return this.dataValue;
    }

    get chartOptions() {
        return {
            ...this.defaultOptions,
            ...this.optionsValue
        }
    }

    get defaultOptions() {
        return {};
    }

    /**
     * Shamelessly adapted from https://stackoverflow.com/a/43026361/4744994
     * This will draw text on the canvas itself in a responsive manner
     * It is recommended to add to the options configuration:
     * responsiveAnimationDuration: 100
     * as this seems to prevent an infinite loop of resizing. Perhaps this is fixed in newer chart.js versions?
     * Example object to configure this:
     * elements: {
     *         center: {
     *           text: "Your text here",
     *           color: "#3369FF", # Default is #000000
     *           fontStyle: "Arial", # Default is Arial
     *           fontWeight: "bold",
     *           sidePadding: 20, # Default is 20 (as a percentage)
     *           minFontSize: 20, # Default is 20 (in px), set to false and text will not wrap.
     *           lineHeight: 25 # Default is 25 (in px), used for when text wraps
     *         }
     *       }
     **/
    drawCenterText() {
        Chart.pluginService.register({
            beforeDraw: (chart) => {
                // To enable center text just define this element in your chart's options
                if (chart.config.options.elements.center) {
                    // Get ctx from string
                    const ctx = chart.chart.ctx;

                    // Get options from the center object in options
                    const centerConfig = chart.config.options.elements.center;
                    const fontStyle = centerConfig.fontStyle || 'Arial';
                    const fontWeight = centerConfig.fontWeight || '';
                    const txt = centerConfig.text;
                    const color = centerConfig.color || '#000';
                    const maxFontSize = centerConfig.maxFontSize || 75;
                    const sidePadding = centerConfig.sidePadding || 20;
                    const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
                    // Start with a base font of 30px
                    ctx.font = fontWeight + " 30px " + fontStyle;

                    // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                    const stringWidth = ctx.measureText(txt).width;
                    const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

                    // Find out how much the font can grow in width.
                    const widthRatio = elementWidth / stringWidth;
                    const newFontSize = Math.floor(30 * widthRatio);
                    const elementHeight = (chart.innerRadius * 2);

                    // Pick a new font size so it will not be larger than the height of label.
                    let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                    let minFontSize = centerConfig.minFontSize;
                    let lineHeight = centerConfig.lineHeight || 25;
                    let wrapText = false;

                    if (minFontSize === undefined) {
                        minFontSize = 20;
                    }

                    if (minFontSize && fontSizeToUse < minFontSize) {
                        fontSizeToUse = minFontSize;
                        wrapText = true;
                    }

                    // Set font settings to draw it correctly.
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    let centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                    let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
                    ctx.font = fontWeight + " " + fontSizeToUse + "px " + fontStyle;
                    ctx.fillStyle = color;
                    if (!wrapText) {
                        ctx.fillText(txt, centerX, centerY);
                        return;
                    }

                    const words = `${txt}`.split(' '); // Make sure not to error out
                    let line = '';
                    let lines = [];

                    // Break words up into multiple lines if necessary
                    for (let n = 0; n < words.length; n++) {
                        const testLine = line + words[n] + ' ';
                        const metrics = ctx.measureText(testLine);
                        const testWidth = metrics.width;
                        if (testWidth > elementWidth && n > 0) {
                            lines.push(line);
                            line = words[n] + ' ';
                        } else {
                            line = testLine;
                        }
                    }

                    // Move the center up depending on line height and number of lines
                    centerY -= (lines.length / 2) * lineHeight;


                    for (let m = 0; m < lines.length; m++) {
                        ctx.fillText(lines[m], centerX, centerY);
                        centerY += lineHeight;
                    }
                    //Draw text in center
                    ctx.fillText(line, centerX, centerY);
                }
            }
        });
    }
}

