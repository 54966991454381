import { ProjectsForm } from 'src/projectsForm'

$(document).on('turbo:load', ()=> {
  var projectsForm = new ProjectsForm()

  // listen for action menu
  $('#add-to-project-button').on('click', function() {
    // grab the select finding ids
    var findings = []
    var checkboxes = document.querySelectorAll('#findings-table input[type=checkbox]:checked')

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].name === 'finding_ids[]') { continue; }

      var finding = {
        id: '<a data-turbo="false" target="_blank" rel="noopener" href="/reports/unmet_requirements/22/edit">' + checkboxes[i].value + '</a>',
        name: checkboxes[i].name,
        project_name: checkboxes[i].getAttribute('project')
      }
      findings.push(finding)
    }

    projectsForm.appendFindingsToForm(findings);

    return false;
  })
})

