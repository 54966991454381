import {TreeGrid, Sort, Filter, Toolbar, Resize, Page} from '@syncfusion/ej2-treegrid'
import {onTurboLoad, beforeTurboCache} from 'src/turbo'
import {defaultGridOptions} from 'src/dataGrid/common'

TreeGrid.Inject(Sort, Filter, Toolbar, Resize, Page)

onTurboLoad(() => {
    const treeId = 'ScoreSettingsTreeGrid'
    const treeElement = document.getElementById(treeId)
    if (treeElement == null)
        return

    const rawJson = treeElement.dataset.scoreSettingTreeData
    const data = JSON.parse(rawJson)

    const gridOptions = {
        toolbar: [{text: 'Search', align: 'left'}, 'ExpandAll', 'CollapseAll'],
    }

    let treeGrid = new TreeGrid(Object.assign(
        {},
        defaultGridOptions,
        gridOptions,
        {
            dataSource: data,
            childMapping: 'children',
            columns: [
                {field: 'name', headerText: 'Name', width: 55},
                {field: 'state', headerText: 'Data Source', width: 25},
                {field: 'source_org_area', headerText: 'Details', width: 25, disableHtmlEncode: false},
                {field: 'last_assessed', headerText: 'Last Assessed', width: 25},
            ]
        }
    ))

    beforeTurboCache(() => treeGrid.destroy())

    treeGrid.appendTo(`#${treeId}`)
})
