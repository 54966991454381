import {Controller} from "@hotwired/stimulus"

/**
 * This controller can be used to show and hide fields scoped to where the controller is added and what targets
 * you define. The below example assumes you have read a bit about how stimulusJS works.
 *
 * Example:
 * <div data-controller="forms--fields"><%# Connect the controller (this one is nested in a folder, thus the forms-- prefix) %>
 *   <%# Define the action to call on the connected controller and set the select target to this select element %>
 *   <%= f.select :state, OrganizationalAreaScoreSetting::states.map { |c| [c[0].to_s.titleize, c[0]] },
 *                {},
 *                autocomplete: "off",
 *                data:         { action: "change->forms--fields#changed", 'forms--fields-target': "select" } %>
 *
 *   <%# Set the element target to this element (this is the element that will be shown or hidden) %>
 *   <%# Configure the values we want to have this element shown for %>
 *   <%= field_set_tag "Source Organizational Area", data: { 'forms--fields-target': "element", values: "inherit" } do %>
 *     <%= f.select :source_organizational_area, f.object.eligible_source_org_areas %>
 *   <% end %>
 * </div>
 */

export default class extends Controller {
    static targets = ["element", "select"]

    connect() {
        if (this.hasSelectTarget) {
            this._toggle(this.elementTarget, this.elementTarget.dataset.values, this.selectTarget.value);
        }
        if (this.hasSelectTargets) {
            for (let select of this.selectTargets) {
                this._toggle(this.elementTarget, this.elementTarget.dataset.values, select.value);
            }
        }
    }

    changed(event) {
        if (this.hasElementTarget) {
            this._toggle(this.elementTarget, this.elementTarget.dataset.values, event.target.value);
        }
        if (this.hasElementTargets) {
            for (let element of this.elementTargets) {
                this._toggle(element, element.dataset.values, event.target.value);
            }
        }
    }

    _toggle(element, values, value) {
        if (element && values) {
            let hidden = true;
            for (let _value of values.split(",")) {
                if (_value === value) {
                    hidden = false;
                }
            }
            element.hidden = hidden;
        }
    }
}
