import 'src/dataGrid/fusion.scss'
import 'src/dataGrid/custom.scss'
import { onTurboLoad } from 'src/turbo'
import { findingsGrid } from 'src/dataGrid/grids/findings'
import { eventsGrid } from 'src/dataGrid/grids/events'
import { remediationProjectsGrid } from 'src/dataGrid/grids/remediationProjects'
import { comparisonsGrid } from 'src/dataGrid/grids/comparisons'
import { assessmentsGrid } from 'src/dataGrid/grids/assessments'
import { usersGrid } from 'src/dataGrid/grids/users'
import { evidenceArtifactsGrid } from 'src/dataGrid/grids/evidence_artifacts'
import { evidenceCategoriesGrid } from 'src/dataGrid/grids/evidence_categories'
import { organizationalAreasGrid } from 'src/dataGrid/grids/organizational_areas'
import { reportRequirementsGrid } from 'src/dataGrid/grids/report_requirements'
import { reportsGrid } from 'src/dataGrid/grids/reports'
import { reportTemplatesGrid } from 'src/dataGrid/grids/report_templates'

const grids = [
  findingsGrid,
  eventsGrid,
  remediationProjectsGrid,
  comparisonsGrid,
  assessmentsGrid,
  usersGrid,
  evidenceArtifactsGrid,
  evidenceCategoriesGrid,
  organizationalAreasGrid,
  reportTemplatesGrid,
  reportsGrid,
  reportRequirementsGrid
]

function showFallbackTable(id) {
  document.getElementById(id).style = 'display: initial;'
}

grids.forEach((g) => {
  onTurboLoad(() => {
    try {
      const gridElement = document.getElementById(g.id)
      if (gridElement === null) {
        return
      }

      g.load()
    } catch (error) {
      console.error(error)
      showFallbackTable(g.id)
    }
  })
})
