import { ExcelExport } from '@syncfusion/ej2-grids'
import { beforeTurboCache } from 'src/turbo'
import { BLGrid,
         defaultGridOptions,
         columnsFromTable,
         getGrid,
         pluckTableData,
         csvExport } from 'src/dataGrid/common'

BLGrid.Inject(ExcelExport)

const gridId = 'projects-datagrid'
const gridOptions = {
  toolbar: [
    { text: 'Search', align: 'left' },
    { text: 'CSV Export', id: 'data-grid-csvexport', align: 'right' }
  ],
  toolbarClick: (args) => {
    const grid = getGrid(gridId)
    if (args.item.id === 'projects-datagrid_search') { grid.columns.forEach(c => c.exporting = false) }
    if (args.item.id === 'data-grid-csvexport') { csvExport(grid) }
  },
  dataBound: () => {
    Foundation.reInit($('.syncfusion-foundation-grid')) // This toggle elements off
    Foundation.reInit($('.syncfusion-foundation-grid')) // This toggle elements on
  }
}

export const remediationProjectsGrid = {
  id: gridId,
  load: () => {
    const data = pluckTableData('#grid-fallback-table')
    const grid = new BLGrid({
      ...defaultGridOptions,
      ...gridOptions,
      columns: columnsFromTable(false),
      dataSource: data
    });

    beforeTurboCache(() => grid.destroy())
    grid.appendTo(`#${gridId}`)
  }
}
