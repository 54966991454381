import jQuery from 'jquery'

export class SimulationsForm {
  constructor() {
    this.form = document.getElementById('simulation-form')
    this.addFormTargetListener()
    this.createResponseHandler()
    this.deleteResponseHandler()
    this.bulkDeleteResponseHandler()
    this.hiddenFieldName = 'finding_ids[]'
  }

  appendFindingsToForm(findingIds) {
    this.toggleFindingsWarning('none')
    this.cleanupInputs()

    findingIds.forEach(id => {
      const input = `<input type=hidden name=${this.hiddenFieldName} value=${id}></input>`
      this.form.insertAdjacentHTML('beforeend', input)
    })

    const pluralizedNoun = findingIds.length == 1 ? 'finding' : 'findings'
    const msg = `
      Which simulation do you want the ${findingIds.length}
      selected ${pluralizedNoun} added to?
    `
    document.getElementById('add-to-simulation-inquiry').innerHTML = msg
    jQuery('#add-to-simulation').foundation('toggle')
  }

  addFormTargetListener() {
    if (this.form == undefined) return

    document.getElementById('simulation-select').addEventListener('change', (e) => {
      const formSubmit = this.form.querySelector('input[type="submit"]')
      if (e.target.value == 'Select Simulation') {
        formSubmit.setAttribute('disabled', 'disabled')
      } else {
        this.form.action = `/simulations/${e.target.value}/simulation_findings`
        formSubmit.removeAttribute('disabled')
      }
    })
  }

  createResponseHandler() {
    // NOTE: using event delegation here resulted in a duped event listener here
    // in some Turbolinks use cases (specifically "back" navigation).
    jQuery('#simulation-form').on('ajax:success', (ev, data) => {
      const simulationId = data.simulation_id
      const simulationContainer = document.getElementById(`simulation-${simulationId}`).querySelector('.simulation-findings')
      data.simulation_findings.forEach(record => {
        const findingID = record.finding_id
        const href = `/reports/unmet_requirements/${findingID}/edit`
        if (simulationContainer.querySelector(`a[href="${href}"]`) != null) {
          return
        }
        const links = `
          <div class="simulation-finding">
            <button class="finding-button" type="button" data-toggle="dropdown-simulation-${simulationId}-finding-${findingID}">${findingID}</button>
            <div class="dropdown-pane dropdown-medium-size" id="dropdown-simulation-${simulationId}-finding-${findingID}" data-dropdown data-auto-focus="true" data-close-on-click="true">
              <a href=${href} target=_blank>View</a>
              |
              <a class="delete-simulation-finding" data-remote="true" rel="nofollow" data-method="delete" href="/simulations/${simulationId}/simulation_findings/${record.id}">Remove</a>
            </div>
          </div>
        `
        simulationContainer.insertAdjacentHTML('afterbegin', links)
        var dropdown_element = jQuery(`#dropdown-simulation-${simulationId}-finding-${findingID}`)
        new Foundation.Dropdown(dropdown_element)
      })

      this.cleanupInputs()
      jQuery('#add-to-simulation').foundation('close')
    })

    jQuery(document).on('ajax:error', '#simulation-form', () => {
      this.toggleFindingsWarning('initial')
    })
  }

  deleteResponseHandler() {
    jQuery(document).on('ajax:success', '.delete-simulation-finding', (ev) => {
      ev.target.parentNode.parentNode.remove()
    })
  }

  bulkDeleteResponseHandler() {
    jQuery('.simulation-remove-all-form').on('ajax:success', (ev, data) => {
      const simulationId = data.simulation_id
      const simulationContainer = document.getElementById(`simulation-${simulationId}`).querySelector('.simulation-findings')
      const findings = simulationContainer.querySelectorAll('.simulation-finding')
      findings.forEach(f => simulationContainer.removeChild(f))
    })
  }

  cleanupInputs() {
    const findingInputs = this.form.querySelectorAll(`input[name="${this.hiddenFieldName}"]`)
    findingInputs.forEach(input => this.form.removeChild(input))
  }

  toggleFindingsWarning(displayVal) {
    document.getElementById('simulation-warning').style.display = displayVal
  }
}
