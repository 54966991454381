import { onTurboLoad, beforeTurboCache } from 'src/turbo'

const FADE_ELEMENT_SELECTOR = '[data-role=fade-after-interval]'

function clearMarkedElementsAfterInterval() {
  $(FADE_ELEMENT_SELECTOR).each((idx, elem) => {
    window.setTimeout(() => { $(elem).fadeOut(750) }, 5000)
  })
}

onTurboLoad(() => clearMarkedElementsAfterInterval())
$(document).ajaxComplete((evt, xhr, jQueryXhr) => {
  if (jQueryXhr.url.match(/sessions\/status.js/)) { return }

  clearMarkedElementsAfterInterval()
})

beforeTurboCache(() => $(FADE_ELEMENT_SELECTOR).remove())
