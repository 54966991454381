import {Controller} from '@hotwired/stimulus';

const Chart = require('chart.js'); // Using chart.js 2.9.4 from package.json (normally 3.x would be used)

// Shamelessly adapted from https://www.stimulus-components.com/docs/stimulus-chartjs/ and adapted to be compatible
// with chart.js 2.9.4 instead of 3.x which it normally would use.
// Use this controller to get a basic chartjs chart on the page.
// Example canvas element
// <canvas
//         data-controller="charts--chart"
//         data-charts--chart-type-value="line"
//         data-charts--chart-options-value="<%= chartjs options json blob goes here %>"
//         data-charts--chart-data-value="<%= chartjs json blob goes here %>"></canvas>
export default class extends Controller {
    static targets = ['canvas'];
    static values = {
        type: {
            type: String,
            default: 'line'
        },
        data: Object,
        options: Object
    };

    connect() {
        const element = this.hasCanvasTarget ? this.canvasTarget : this.element;
        this.chart = new Chart(element.getContext('2d'), {
            type: this.typeValue,
            data: this.chartData,
            options: this.chartOptions
        });
        this.chart.generateLegend();
    }

    disconnect() {
        this.chart.destroy();
        this.chart = undefined;
    }

    get chartData() {
        if (!this.hasDataValue) {
            console.warn('[stimulus-chartjs] You need to pass data as JSON to see the chart.');
        }
        return this.dataValue;
    }

    get chartOptions() {
        return {
            ...this.defaultOptions,
            ...this.optionsValue
        }
    }

    get defaultOptions() {
        return {}
    }
}

