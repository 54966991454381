import jQuery from 'jquery'
import { extractIdFromLink } from 'src/dataGrid/common'

export class IntegrationsForm {
  constructor() {
    this.form = document.getElementById('integration-form')
    this.findingsList = document.getElementById('selected-findings-list')
    this.findingIds = 'finding_ids[]'
    this.formSubmit = this.form.querySelector('input[type="submit"]')

    jQuery(this).on('integrations-form:clear', this.cleanForm)
  }

  load(findings) {
    this.cleanForm()
    this.appendFindingsToForm(findings)
  }

  appendFindingsToForm(findings) {
    findings.forEach(f => {
      const id = extractIdFromLink(f.id)

      const ids = `<input type=hidden name=${this.findingIds} value=${id}></input>`
      this.form.insertAdjacentHTML('beforeend', ids)

      const li = document.createElement('li')
      li.appendChild(document.createTextNode(`${id} - ${f.name}`))
      this.findingsList.appendChild(li)
    })

    const statement = `<p>Selected Findings (${findings.length})</p>`
    document.getElementById('selected-findings-statement').innerHTML = statement

    if (findings.length > 0) {
      this.formSubmit.removeAttribute('disabled')
    }

    jQuery('#integrate-findings').foundation('toggle')
  }

  cleanForm() {
    const findingInputs = this.form.querySelectorAll(`input[name="${this.findingIds}"]`)
    findingInputs.forEach(input => this.form.removeChild(input))

    const alertCallout = document.getElementById('integration-form-alert')
    if (alertCallout) {
      alertCallout.remove()
    }

    this.findingsList.innerHTML = ''
    this.formSubmit.setAttribute('disabled', 'disabled')

    jQuery('#integration-form input[name=name]').val('')
  }
}
