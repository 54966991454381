import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ["checkbox", "form", "outputField"];
    static values = {
        counter: Number
    }

    connect() {
        this.checkboxTargets.forEach(element => {
            if (element.checked) {
                this.increment();
            }
        })
    }

    check(event) {
        let element = event.target;
        if (element.checked) {
            this.increment();
        } else {
            this.decrement();
        }
    }

    conditionalSubmit(event) {
        // Check to see if the user has caused the counter to be > 1 and emit our event instead of submitting!
        if (this.counterValue >= 1) {
            event.preventDefault();
            const reportFrameworkSelectedEvent = new CustomEvent('reportFrameworkSelected');
            window.dispatchEvent(reportFrameworkSelectedEvent);
        }
    }

    submit() {
        this.formTarget.submit();
    }

    selected(event) {
        this.outputFieldTarget.value = event.target.value;
    }

    increment() {
        this.counterValue += 1
    }

    decrement() {
        this.counterValue -= 1
    }
}
