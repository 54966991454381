import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  answer(singleChoiceController, removeOverlayController, childQuestion, index, timeOutinit, timeOutmult, external, selectedBooleanValue, questionOptionText) {
    setTimeout(() => {
      let overlayActive = true
      let QuestionIndex = 0
      let hasOverlayActive = true
      document.body.querySelectorAll("div[data-external='" + external + "'] .choices .choice a.selected").forEach((choice) => {
        QuestionIndex++
        if (choice.classList.contains('selected')) {
          let hrefAttributes = choice.getAttribute('href').split("=");
          let selectedOptionValue = hrefAttributes[1];
          if (selectedOptionValue === selectedBooleanValue) {
            overlayActive = true
          }
          else {
            if (childQuestion.dataset.value.includes(selectedOptionValue)) {
              overlayActive = false
            }
          }
        }
      })
      if (overlayActive === false || QuestionIndex === 0) {
        hasOverlayActive = childQuestion.parentElement.classList.contains('active');
        if (hasOverlayActive) {
          removeOverlayController._removeQuestionOverlay(childQuestion)
          singleChoiceController._singleChoice(childQuestion, questionOptionText)
        }
      }
      else {
        childQuestion.parentElement.classList.add('active')
        singleChoiceController._singleChoice(childQuestion, questionOptionText)
      }
    }, (index == 0) ? timeOutinit : timeOutinit + (index * timeOutmult));
  }
}
