import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  _singleChoice(childQuestion, questionOptionText) {
    questionOptionText = questionOptionText.trim();
    let findText = false
    let inputDefault, inputText
    inputDefault = inputText = "\"I don't know\" because of the answer to a previous question.";
    let overlayActive = childQuestion.parentElement.classList.contains('active');
    childQuestion.parentElement.querySelectorAll("textarea").forEach((textarea) => {
      if (questionOptionText === "I don't know.") {
        if (!overlayActive) {
          inputText = "";
          textarea.classList.remove('required')
        }
        else {
          textarea.classList.add('required')
        }
        textarea.value = inputText;
        textarea.innerHTML = inputText;
      }
      else {
        textarea.innerHTML = "";
        textarea.value = "";
        textarea.classList.remove('required')
      }
      // Forces trigger change using jQuery
      $( textarea ).trigger( 'change' );
    });
    childQuestion.querySelectorAll("div.choice").forEach((choice) => {
      // let textElement = choice.getElementsByClassName('text');
      // let choice_text = textElement[1].innerText.trim();
      let choice_text = $(choice).find('span.text').text();

      const substring = 'Never';
      const substring_2 = 'No';
      const substring_3 = 'None of the above.';
      const substring_4 = 'N/A';
      const substring_5 = 'Not applicable to the organization’s environment';
      const substring_6 = "Not applicable to the organization's environment";
      const substring_7 = "I don't know.";
      const defaultOptions = [substring, substring_2, substring_3, substring_4, substring_5, substring_6, substring_7];
      let selected = choice.getElementsByClassName('response')[0];
      if (!overlayActive) {
        if (choice_text === questionOptionText) {
          if (selected.classList.contains('selected')) {
            choice.getElementsByClassName('response')[0].click()
            findText = true
          }
        }
        else if (defaultOptions.includes(choice_text) && findText == false) {
          if (selected.classList.contains('selected')) {
            choice.getElementsByClassName('response')[0].click()
            findText = true
          }
        }
        if (choice_text === substring_7) {
          childQuestion.parentElement.querySelectorAll("textarea").forEach((textarea) => {
            textarea.innerHTML = "";
            textarea.value = "";
            textarea.classList.remove('required')
            $( textarea ).trigger( 'change' );
          });
        }
      }
      else {
        if (questionOptionText === "I don't know.") {
          if (choice_text === questionOptionText) {
            if (!selected.classList.contains('selected')) {
              choice.getElementsByClassName('response')[0].click()
              childQuestion.parentElement.querySelectorAll("textarea").forEach((textarea) => {
                textarea.innerHTML = inputDefault;
                textarea.value = inputDefault;
                textarea.classList.add('required')
                $( textarea ).trigger( 'change' );
              });
              return questionOptionText;
            }
          }
        }
        else {
          if (choice_text === questionOptionText) {
            if (!selected.classList.contains('selected')) {
              choice.getElementsByClassName('response')[0].click()
            }
            findText = true
          }
          else if (defaultOptions.includes(choice_text) && findText == false) {
            if (!selected.classList.contains('selected')) {
              choice.getElementsByClassName('response')[0].click()
              if(choice_text === substring_7) {
                childQuestion.parentElement.querySelectorAll("textarea").forEach((textarea) => {
                  textarea.innerHTML = inputDefault;
                  textarea.value = inputDefault;
                  textarea.classList.add('required')
                  $( textarea ).trigger( 'change' );
                });
              }
            }
            findText = true
          }
        }
      }
    });
  }
}
