import { DropDownList } from '@syncfusion/ej2-dropdowns';

export class FindingsGridRisksDropdown {
  constructor(containerId, currentRiskCode, riskOptions, currentView) {
    this.containerId = containerId
    this.currentRiskCode = currentRiskCode || ''
    this.riskOptions = riskOptions || []
    this.currentView = currentView || 'default'
    this.dropdown = null
  }

  render() {
    this.dropdown = new DropDownList({
      created: () => {
        let currentRiskName
        if (this.currentRiskCode) {
          let currentRisk = this.riskOptions.find((opt) => {
            return opt.code === this.currentRiskCode
          })
          currentRiskName = currentRisk.name
        }
        this.dropdown.text =  currentRiskName || ''

        // Needs to happen on create otherwise dropdown options aren't available.
        if (this.currentView !== 'risks') {
          // The second toolbar item is the risk dropdown as defined in ./grids/findings.js
          let riskDropDownWrapper = document.getElementsByClassName('e-toolbar-item')[1]
          riskDropDownWrapper.classList.add('hidden')
        }
      },
      select: (event) => {
        const url = new URL(document.location.href)
        const riskCode = event.itemData.code
        url.searchParams.set('risk_code', riskCode)
        url.searchParams.set('view', 'risks')
        window.location.replace(url)
      },
      change: (event) => {
        // Runs on risk clear to ensure we have proper findings data
        // which requires a page reload.
        if (event.value === null) {
          const url = new URL(document.location.href)
          url.searchParams.delete('risk_code')
          window.location.replace(url)
        }
      },
      dataSource: this.riskOptions,
      showClearButton: true,
      fields: { text: 'name', value: 'code' },
      placeholder: 'Select Risk',
      cssClass: 'findings-risk-dropdown'
    })

    this.dropdown.appendTo(`#${this.containerId}`)
  }

  reset() {
    this.dropdown.clear();
  }
}
