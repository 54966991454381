import { csvExport, extractIdFromLink, selectedGroupedFindings } from 'src/dataGrid/common'
import { featureFlagEnabled } from 'src/FeatureFlags';
import { ProjectsForm } from 'src/projectsForm'
import { SimulationsForm } from 'src/simulationsForm'
import { FindingsBulkUpdateForm } from 'src/FindingsBulkUpdateForm'
import { IntegrationsForm } from 'src/integrationsForm'
import { DropDownButton } from '@syncfusion/ej2-splitbuttons';

const dropDownItems = {
  tickets: { text: 'Create Tickets', id: 'create_tickets' },
  add_to_project: { text: 'Add to Project', id: 'add_to_project' },
  simulation: { text: 'Add to Simulation', id: 'add_to_simulation' },
  export: { text: 'Export to CSV', id: 'export_to_csv' },
  findings_bulk_update: { text: 'Update Multiple Findings', id: 'findings_bulk_update' }
}
/**
 * A class for rendering actions that can be taken against a Findings Grid.
 * */
export class FindingsGridActionDropDown {
  /**
   * Create a FindingsGridActionDropDown
   * @param {Grid} grid - The grid the dropdown is inserting itself into
   * @param {string} id - The id to append the DropDownButton
   * @param {Boolean} canCreateProject - determines the ability to create a project
   */
  constructor(grid, id, canCreateProject, trayIntegrationEnabled) {
    this.grid = grid
    this.dropdown = null
    this.containerId = id
    this.canCreateProject = !!canCreateProject
    this.trayIntegrationEnabled = !!trayIntegrationEnabled

    if(featureFlagEnabled('update_multiple_findings_syncfusion')) {
      this.findingsBulkUpdateForm = new FindingsBulkUpdateForm()
    }

    if (this.canCreateProject) {
      this.simulationsForm = new SimulationsForm()
      this.projectsForm = new ProjectsForm()
    }

    if (this.trayIntegrationEnabled) {
      this.integrationsForm = new IntegrationsForm()
    }
  }

  /**
   * Cleans up an existing events and dom elements and renders them
   */
  render() {
    if (this.dropdown) {
      this.dropdown.destroy()
    }

    let dataSource = []
    if (this.trayIntegrationEnabled) {
      dataSource.push(dropDownItems['tickets'])
    }

    if(this.canCreateProject) {
      dataSource.push(dropDownItems['add_to_project'])
      dataSource.push(dropDownItems['simulation'])
    }

    dataSource.push(dropDownItems['export'])

    if(featureFlagEnabled('update_multiple_findings_syncfusion')) {
      dataSource.push(dropDownItems['findings_bulk_update'])
    }

    this.dropdown = new DropDownButton({
      items: dataSource,
      cssClass: 'e-caret-hide',
      iconCss: 'ellipsis-icon',
      iconPosition: 'Center',
      select: (args) => {
        switch (args.item.properties.id) {
          case dropDownItems['tickets']['id']: {
            this.integrationsForm.load(this.selectedFindings());
            break;
          }
          case dropDownItems['add_to_project']['id']: {
            this.projectsForm.appendFindingsToForm(this.selectedFindings());
            break;
          }
          case dropDownItems['simulation']['id']: {
            this.simulationsForm.appendFindingsToForm(this.selectedFindingIds());
            break;
          }
          case dropDownItems['export']['id']: {
            this.handleCsvExport();
            break;
          }
          case dropDownItems['findings_bulk_update']['id']: {
            this.findingsBulkUpdateForm.openForm(this.selectedFindings(), this.grid);
            break;
          }
        }
      }
    })

    this.dropdown.appendTo(`#${this.containerId}`)
  }

  selectedFindingIds() {
    let selectedOrCheckedFindings
    if(this.grid.groupSettings.columns.length > 0) {
      selectedOrCheckedFindings = selectedGroupedFindings(this.grid)
    } else {
      selectedOrCheckedFindings = this.grid.getSelectedRecords()
    }

    return selectedOrCheckedFindings.map(r => extractIdFromLink(r.id))
  }

  selectedFindings() {
    if(this.grid.groupSettings.columns.length > 0) {
      return selectedGroupedFindings(this.grid)
    } else {
      return this.grid.getSelectedRecords()
    }
  }

  handleCsvExport() {
    csvExport(this.grid)
  }
}
