import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  response() {
    this._checkSelectedElement(this.element)
  }

  _checkSelectedElement(elem) {
    const singleChoiceController = this.application.getControllerForElementAndIdentifier(document.getElementById('elimination-question-loader'), 'gates--single-choice')
    const removeOverlayController = this.application.getControllerForElementAndIdentifier(document.getElementById('elimination-question-loader'), 'gates--remove-overlay')
    const gatesEqualController = this.application.getControllerForElementAndIdentifier(document.getElementById('elimination-question-loader'), 'gates--equal')
    const gatesNotEqualController = this.application.getControllerForElementAndIdentifier(document.getElementById('elimination-question-loader'), 'gates--not-equal')
    const gatesInController = this.application.getControllerForElementAndIdentifier(document.getElementById('elimination-question-loader'), 'gates--in')
    const gatesNotInController = this.application.getControllerForElementAndIdentifier(document.getElementById('elimination-question-loader'), 'gates--not-in')
    const Controllers = {
      equal: gatesEqualController,
      not_equal: gatesNotEqualController,
      in: gatesInController,
      not_in: gatesNotInController,
    }

    const textElement = elem.getElementsByClassName('text');
    let questionOptionText = textElement[1].innerText
    let container = elem.parentElement.parentElement.parentElement
    const external = container.dataset.external;
    const selectedBooleanValue = '1'
    let hasOverlayActive = true
    let timeOutinit = 1000
    let timeOutmult = 500
    if (elem.classList.contains('selected')) {
      document.querySelectorAll("div[data-id='" + external + "']").forEach((childQuestion, index) => {
        switch(childQuestion.dataset.operator) {
          case 'equal':
          case 'not_equal':
            setTimeout(() => {
              hasOverlayActive = childQuestion.parentElement.classList.contains('active');
              if (hasOverlayActive) {
                removeOverlayController._removeQuestionOverlay(childQuestion)
                singleChoiceController._singleChoice(childQuestion, questionOptionText)
              }
            }, (index == 0) ? timeOutinit : timeOutinit + (index * timeOutmult));
            break;
          
          default:
            Controllers[childQuestion.dataset.operator].answer(singleChoiceController, removeOverlayController, childQuestion, index, timeOutinit, timeOutmult, external, selectedBooleanValue, questionOptionText)
            break;
        }
      })
    }
    else {
      document.querySelectorAll("div[data-id='" + external + "']").forEach((childQuestion, index) => {
        Controllers[childQuestion.dataset.operator].answer(singleChoiceController, removeOverlayController, childQuestion, index, timeOutinit, timeOutmult, external, selectedBooleanValue, questionOptionText)
      });
    }
  }

  _toggle() {
    let parentWrapper = this.element.parentElement
    if (parentWrapper.classList.contains('collapsible_show')) {
      parentWrapper.classList.add('collapsible_hidden');
      parentWrapper.classList.remove('collapsible_show');
      this.element.parentElement.parentElement.parentElement.parentElement.classList.add('collapsible')
    }
    else {
      parentWrapper.classList.add('collapsible_show');
      parentWrapper.classList.remove('collapsible_hidden');
      this.element.parentElement.parentElement.parentElement.parentElement.classList.remove('collapsible')
    }
  }
}
