import { TreeView } from '@syncfusion/ej2-navigations'
import { onTurboLoad, beforeTurboCache } from 'src/turbo'

onTurboLoad(() => {
  const treeId = 'framework-category-tree-view'
  const treeElement = document.getElementById(treeId)
  if (treeElement == null) {
    return
  }

  const rawJson = treeElement.dataset.frameworkCategoryTreeData
  const data = JSON.parse(rawJson)
  const tree = new TreeView({
    fields: { dataSource: data, id: 'id', text: 'name', child: 'children' }
  })

  beforeTurboCache(() => tree.destroy())
  tree.appendTo(`#${treeId}`)
})
