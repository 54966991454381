import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this._gateQuestions(this.element)
  }

  _gateQuestions() {
    let initializerQuestion = this
    const selectedBooleanValue = '1'
    document.querySelectorAll("div[class='choices-container']").forEach((container) => {
      var questionOptionText = $(container).find('a.selected span.text').text();
      if (questionOptionText != '') {
        let external = container.getAttribute('data-external');
        document.querySelectorAll("div[data-id='" + external + "']").forEach((childQuestion) => {
          switch(childQuestion.dataset.operator) {
            case 'equal':
              document.body.querySelectorAll("div[data-external='" + external + "'] .choices .choice a.selected").forEach((choice) => {
                if (choice.classList.contains('selected')) {
                  let hrefAttributes = choice.getAttribute('href').split("=");
                  let selectedOptionValue = hrefAttributes[1];
                  /*let textElement = choice.getElementsByClassName('text');
                  let questionOptionText = textElement[1].innerText*/
                  if (selectedOptionValue === selectedBooleanValue) {
                    initializerQuestion._singleChoice(childQuestion, questionOptionText)
                  }
                  else {
                    if (childQuestion.dataset.value !== questionOptionText) {
                      initializerQuestion._singleChoice(childQuestion, questionOptionText)
                    }
                  }
                }
              })
              break;
    
            case 'not_equal':
              document.body.querySelectorAll("div[data-external='" + external + "'] .choices .choice a.selected").forEach((choice) => {
                if (choice.classList.contains('selected')) {
                  let hrefAttributes = choice.getAttribute('href').split("=");
                  let selectedOptionValue = hrefAttributes[1];
                  if (selectedOptionValue === selectedBooleanValue) {
                    initializerQuestion._singleChoice(childQuestion, questionOptionText)
                  }
                  else {
                    if (childQuestion.dataset.value === questionOptionText) {
                      initializerQuestion._singleChoice(childQuestion, questionOptionText)
                    }
                  }
                }
              })
              break;
            
            case 'in':
              setTimeout(() => {
                var overlayActive = true
                document.body.querySelectorAll("div[data-external='" + external + "'] .choices .choice a.selected").forEach((choice) => {
                  if (choice.classList.contains('selected')) {
                    let hrefAttributes = choice.getAttribute('href').split("=");
                    let selectedOptionValue = hrefAttributes[1];
                    if (selectedOptionValue === selectedBooleanValue) {
                      initializerQuestion._singleChoice(childQuestion, questionOptionText)
                    }
                    else {
                      if (childQuestion.dataset.value.includes(selectedOptionValue)) {
                        overlayActive = false
                      }
                    }
                  }
                })
                if (overlayActive === true) {
                  initializerQuestion._singleChoice(childQuestion, questionOptionText)
                }
  
              }, 1000);
              break;
            
            case 'not_in':
              setTimeout(() => {
                var overlayActive = false
                document.body.querySelectorAll("div[data-external='" + external + "'] .choices .choice a.selected").forEach((choice) => {
                  if (choice.classList.contains('selected')) {
                    let hrefAttributes = choice.getAttribute('href').split("=");
                    let selectedOptionValue = hrefAttributes[1];
                    if (selectedOptionValue === selectedBooleanValue) {
                      initializerQuestion._singleChoice(childQuestion, questionOptionText)
                    }
                    else {
                      if (childQuestion.dataset.value.includes(selectedOptionValue)) {
                        overlayActive = true
                      }
                    }
                  }
                })
                if (overlayActive === true) {
                  initializerQuestion._singleChoice(childQuestion, questionOptionText)
                }
  
              }, 1000);
              break;
          }
        });
      }
    });
  }

  _singleChoice(childQuestion, questionOptionText) {
    childQuestion.parentElement.classList.add('active')
    let inputDefault, inputText
    inputDefault = inputText = "\"I don't know\" because of the answer to a previous question.";
    let overlayActive = childQuestion.parentElement.classList.contains('active');
    childQuestion.parentElement.querySelectorAll("textarea").forEach((textarea) => {
      if (questionOptionText === "I don't know.") {
        if (!overlayActive) {
          inputText = "";
          textarea.classList.remove('required')
        }
        else {
          textarea.classList.add('required')
        }
        textarea.value = inputText;
        textarea.innerHTML = inputText;
      }
      else {
        textarea.innerHTML = "";
        textarea.value = "";
      }
      textarea.dispatchEvent(new Event('change'));
    });
    childQuestion.querySelectorAll("div.choice").forEach((choice) => {
      let textElement = choice.getElementsByClassName('text');
      let choice_text = textElement[1].innerText.trim();
      const substring = "I don't know.";
      let selected = choice.getElementsByClassName('response')[0]
      if(choice_text === substring) {
        if (selected.classList.contains('selected')) {
          childQuestion.parentElement.querySelectorAll("textarea").forEach((textarea) => {
            textarea.innerHTML = inputDefault;
            textarea.value = inputDefault;
          });
        }
      }
    });
  }
}
