export function featureFlagEnabled(featureFlag) {
  const featureFlags = getFeatureFlags()

  return featureFlags.includes(featureFlag)
}
export function getFeatureFlags() {
  const meta = document.querySelector('meta[name=ui]')
  if(meta) {
    return JSON.parse(meta.content)
  } else {
    return []
  }
}

