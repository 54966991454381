import { Group, ExcelExport, ColumnChooser, LazyLoadGroup, Reorder, Edit} from '@syncfusion/ej2-grids'
import { beforeTurboCache } from 'src/turbo'
import { FindingsGridViewsDropdown } from 'src/dataGrid/findingsGridViewsDropdown'
import { FindingsGridActionDropDown } from 'src/dataGrid/findingsGridActionDropDown'
import { FindingsGridRisksDropdown } from 'src/dataGrid/findingsGridRisksDropdown'
import { BLGrid,
         defaultGridOptions,
         columnsFromTable,
         getGrid,
         pluckTableData
        } from 'src/dataGrid/common'
import { extractIdFromLink } from 'src/dataGrid/common'

BLGrid.Inject(Group, ExcelExport, ColumnChooser, LazyLoadGroup, Reorder, Edit)

export const gridId = 'findings-datagrid'
const selectedFindingsCountContainerId = 'data-grid-toolbar-selected-count'
const viewsDropdownId = 'data-grid-toolbar-views-dropdown'
const risksDropdownId = 'data-grid-toolbar-risks-dropdown'
const actionMenuId = 'data-grid-action-menu'
const groupById = 'data-grid-toolbar-group'


export const viewsGrouping = {
  default: ['program_area', 'common_activity', 'level'],
  maturity: ['level', 'program_area', 'common_activity'],
  capabilities: ['capability', 'level', 'common_activity'],
  risks: [],
}

export const updateSelectedFindingsCount = (grid) => {
  const gridElement = document.getElementById(gridId)
  const selectedCountElem = gridElement.querySelector(`#data-grid-toolbar-selected-count`)
  // NOTE:
  // if we are in group mode, we use getSelectedRows() instead as the source
  // of truth since the group view will only select things that are visible
  // in the grouping and these are what we are going to use in the
  // findingsGridActionDropdown for building projects and simulations
  let selectedCount
  if(grid.groupSettings.columns.length > 0) {
    selectedCount = grid.getSelectedRows().length
  } else {
    selectedCount = grid.getSelectedRecords().length
  }

  selectedCountElem.innerHTML = selectedCount === 0 ? '' : `${selectedCount} Finding${selectedCount === 1 ? '' : 's'} Selected`
}

// Remove the title attribute of group captions on grid render - this makes the grouped rows captionTemplate appear in a tooltip
const removeTitleFromGroupedCaptions = () => {
  let groupCaptions = document.querySelectorAll('td.e-groupcaption')
  if (groupCaptions) {
    groupCaptions.forEach((el) => {
      el.removeAttribute('title')
    })
  }
}

export const findingsGrid = {
  id: gridId,
  load: () => {
    const data = pluckTableData('#grid-fallback-table')
    const url = new URL(document.location.href)
    const gridElement = document.getElementById(gridId)
    const tableElement = document.getElementById('grid-fallback-table')
    let currentView = tableElement.dataset.display || 'default'
    const sAndPEnabled = gridElement.dataset.enableSAndP === 'true'
    const trayIntegrationEnabled = gridElement.dataset.enableTrayIntegration === 'true'
    const riskDropdownOptions = JSON.parse(gridElement.dataset.riskData)
    const risksEnabled = gridElement.dataset.enableRisks === 'true'
    const currentRiskCode = url.searchParams.get('risk_code')
    const columns = columnsFromTable(sAndPEnabled || trayIntegrationEnabled)
    const defaultHiddenColumns = columns.filter((c) => c.visible === false)
    const defaultVisibleColumns = columns.filter((c) => c.visible)

    if (currentRiskCode) {
      currentView = 'risks'
    }

    let contextAwareOptions = {
      ...defaultGridOptions,
      ...gridOptions(currentView),
      columns: columns,
      dataSource: data,
      showColumnChooser: true,
      defaultHiddenColumns: defaultHiddenColumns,
      defaultVisibleColumns: defaultVisibleColumns
    }

    if (sAndPEnabled || trayIntegrationEnabled) {
      contextAwareOptions = {
        ...contextAwareOptions,
        allowSelection: true,
        rowDataBound: (args) => {
          // Writes a data-id attribute to the row element for matching checked
          // rows to selected rows
          args.row.dataset.id =  extractIdFromLink(args.data.id)
        },
        selectionSettings: { persistSelection: true },
        actionBegin: (args) => {
          onActionBegin(args)
        }
      }
    }

    const grid = new BLGrid(contextAwareOptions);

    // the location of the grid.created matters.  It needs to be before grid.appendTo
    grid.created = () => {
      let toolbar = grid.element.querySelector('.e-toolbar');
      grid.element.prepend(toolbar);
    }

    grid.dataBound = () => {
      removeTitleFromGroupedCaptions()
    }

    beforeTurboCache(() => grid.destroy())

    grid.appendTo(`#${gridId}`)

    function onActionBegin(args) {
      if (args.requestType === 'filterbeforeopen' && args.columnType === 'number') {
        // Checkbox type filters do not include a col attr in the filterMode
        if (args.filterModel.options !== undefined && args.filterModel.options.column.filter.type !== 'CheckBox') {
          const FulldataSource = args.filterModel.col.parent.dataSource
          FulldataSource.forEach(function(string, iteration) {
            if (string[args.columnName] !== '' && (typeof string[args.columnName] !== 'number')) {
              args.filterModel.col.parent.dataSource[iteration][args.columnName] = parseInt(string[args.columnName])
            }
          })
        } else {
          // If is a Checkbox type filter whose data is not numeric, converts to number
          const FulldataSource = args.filterModel.parent.dataSource
          FulldataSource.forEach(function(string, iteration) {
            if (string[args.columnName] !== '' && (typeof string[args.columnName] !== 'number')) {
              args.filterModel.parent.dataSource[iteration][args.columnName] = parseInt(string[args.columnName])
            }
          })
        }
      }
      if (args.requestType === 'filterbeforeopen' && args.columnType === 'datetime') {
        const FulldataSource = args.filterModel.col.parent.dataSource
        FulldataSource.forEach(function(string, iteration) {
          if (string[args.columnName] !== '' && (typeof string[args.columnName] === 'string')) {
            let dt = new Date(string[args.columnName])
            args.filterModel.col.parent.dataSource[iteration][args.columnName] = dt
          }
        })
      }
      if (args.requestType === 'filterchoicerequest') {
        // Raise sampling size from 1000 (default) to 10000
        args.filterChoiceCount = 10000
      }
    }

    function gridOptions(currentview) {
      return {
        toolbar: [
          { text: '', id: viewsDropdownId,  align: 'left', width: 200, type: 'Input', template: `<input id="${viewsDropdownId}"/>`  } ,
          { text: '', id: risksDropdownId,  align: 'left', type: 'Input', template: `<input id="${risksDropdownId}"/>` } ,
          { text: 'Search', align: 'left' },
          { text: 'Clear Group By', id: groupById, align: 'left' },
          { text: '', id: selectedFindingsCountContainerId, align: 'left' },
          'ColumnChooser',
          { text: '', id: actionMenuId, align: 'right', type: 'Input', template: `<button id="${actionMenuId}" type="button" class="button action-menu standalone"> </button>` }
        ],
        toolbarClick: ({item}) => {
          const grid = getGrid(gridId)
          if (item && item.id === groupById) {
            grid.columns.forEach(c => c.exporting = false)
            grid.clearGrouping()
          }
          if (item && item.id === 'findings-datagrid_search') { grid.columns.forEach(c => c.exporting = false) }
        },
        groupSettings: {
          showDropArea: true,
          enableLazyLoading: true,
          captionTemplate: "<span class='grouped-header-text'>${headerText} | </span> ${key} (${count} items)",
          columns: viewsGrouping[currentview],
        },
        allowGrouping: true,
        editSettings: { allowEditing: true, allowEditOnDblClick: false, allowAdding: false, allowDeleting: false },
      }
    }

    const actionMenu = new FindingsGridActionDropDown(grid, actionMenuId, sAndPEnabled, trayIntegrationEnabled)
    const viewsDropdown     = new FindingsGridViewsDropdown(viewsDropdownId, currentView, risksEnabled)
    const risksDropDown     = new FindingsGridRisksDropdown(risksDropdownId, currentRiskCode, riskDropdownOptions, currentView)

    const onRowSelect = () => {
      updateSelectedFindingsCount(grid)
    }

    grid.beforeExcelExport = () => {
      grid.setProperties({ groupSettings: { enableLazyLoading: false } }, true);
    }

    grid.excelExportComplete = () => {
      grid.setProperties({ groupSettings: { enableLazyLoading: true } }, true);
    }

    grid.rowSelected = onRowSelect
    grid.rowDeselected = onRowSelect

    grid.lazyLoadGroupExpand = () => {
      // We have to wait for the expanded rows to render before removing attributes from them
      // The delay is arbitrary.  Adjust as needed for performance
      setTimeout(removeTitleFromGroupedCaptions, 350);
    }

    actionMenu.render()
    viewsDropdown.render();
    risksDropDown.render();
    updateSelectedFindingsCount(grid)
  }
}
