import { beforeTurboCache } from 'src/turbo'
import { BLGrid,
         defaultGridOptions,
         columnsFromTable,
         pluckTableData } from 'src/dataGrid/common'

const gridId = 'report-requirements-datagrid'
const gridOptions = {
    toolbar: [{text: 'Search', align: 'left'}],
    dataBound: () => {
        Foundation.reInit($('.syncfusion-foundation-grid')) // This toggle elements off
        Foundation.reInit($('.syncfusion-foundation-grid')) // This toggle elements ona
    }
}
/**
 * For future readers - I did not want to write this code, but it was the fastest way to get things working.
 * I would have preferred to use our Stimulus + Turbo to just do HOTWIRE which would have been simpler,
 * but the CSS from tailwind doesn't play nice with foundation css right now and we don't have Turbo.
 * Also I strongly avoid building HTML in Stimulus as that is not, in my mind, how it is supposed to be
 * used and that is more the domain of full frontend frameworks. Because of this, it was decided that
 * using our existing modal would be the fastest way forward.
 * With Manuel's knowledge we were able to get this put together in short order.
 * */
const getFindings = () => {
    $(".report-modal").click(function () {
        let requirements_snapshot_id = this.dataset.requirementsSnapshotId;
        let element_id = this.dataset.open;
        $.ajax({
            url: "/reports/findings?program_requirements_snapshot_id=" + requirements_snapshot_id,
            success: function (result) {
                let html;
                if (result.length) {
                    html = result.map((finding_id) => {
                        return `<a href="/reports/unmet_requirements/${finding_id}/edit" target="_blank">${finding_id}</a>`;
                    })

                    html = html.join("\n")
                } else {
                    html = `<p>No Findings were found.</p>`
                }
                $(`#${element_id}_content`).html(html)
            }
        });
    })
}

const getProjects = () => {
    $(".report-project-modal").click(function () {
        let requirements_snapshot_id = this.dataset.requirementsSnapshotId;
        let element_id = this.dataset.open;
        $.ajax({
            url: "/reports/projects?program_requirements_snapshot_id=" + requirements_snapshot_id,
            success: function (result) {
                let html;
                if (result.length) {
                    html = result.map((project) => {
                        return `<a href="/projects/${project['id']}" target="_blank">${project['name']}</a>`;
                    })

                    html = html.join("\n")
                } else {
                    html = `<p>No Projects were found.</p>`
                }
                $(`#${element_id}_content`).html(html)
            }
        });
    })
}
export const reportRequirementsGrid = {
    id: gridId,
    load: () => {
        const data = pluckTableData('#grid-fallback-table')
        const grid = new BLGrid({
            ...defaultGridOptions,
            ...gridOptions,
            columns: columnsFromTable(false),
            dataSource: data
        })
        grid.dataBound = () => {
            getFindings();
            getProjects();
        }
        beforeTurboCache(() => grid.destroy())
        grid.appendTo(`#${gridId}`)
    }
}
