import { ColumnChooser, ExcelExport, Reorder } from '@syncfusion/ej2-grids'
import { beforeTurboCache } from 'src/turbo'
import { BLGrid,
         csvExport,
         defaultGridOptions,
         columnsFromTable,
         pluckTableData,
         getGrid } from 'src/dataGrid/common'

BLGrid.Inject(ColumnChooser, ExcelExport, Reorder)

const gridId = 'evidence-categories-datagrid'
const gridOptions = {
  toolbar: [
    { text: 'Search', align: 'left' },
    'ColumnChooser',
    { text: 'Export to CSV', id: 'data-grid-csv-export', align: 'right', cssClass: 'e-cc e-ccdiv e-cc-toolbar' }
  ],
  toolbarClick: ({item}) => {
    const grid = getGrid(gridId)
    if (item && item.id === 'data-grid-csv-export') { csvExport(grid) }
  },
  allowReordering: true
}

export const evidenceCategoriesGrid = {
  id: gridId,
  load: () => {
    const data = pluckTableData('#grid-fallback-table')
    const grid = new BLGrid({
      ...defaultGridOptions,
      ...gridOptions,
      columns: columnsFromTable(false),
      showColumnChooser: true,
      dataSource: data
    });

    beforeTurboCache(() => grid.destroy())
    grid.appendTo(`#${gridId}`)
  }
}
