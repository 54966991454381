window.addEventListener('pageshow', function() {
  const assessment_div = document.getElementsByClassName('new_assessment')
  const errors = document.getElementsByClassName('error')
  const assessmentTypeSelect = document.getElementById('assessment_type_of')
  if (assessment_div.length > 0 && errors.length === 0 && !assessment_div[0].contains(errors[0])) {
    const defaultValue = assessmentTypeSelect.options[0].value
    document.getElementById('assessment_type_of').value = defaultValue
  }

  if (assessmentTypeSelect) {
    const selectedValue = assessmentTypeSelect.options[assessmentTypeSelect.selectedIndex].value
    filterFrameworkSelect(selectedValue)
  }
})

$(document).on('turbo:load', ()=> {
  // disable attributes of for non-CMM frameworks on load
  disableCMMOnlyFields()

  // disable attributes of for non-CMM frameworks on change
  $('#assessment_framework_ids').on('change', function(){
    disableCMMOnlyFields()
  })

  // encourage use of recent CMM framework versions
  $('#assessment_framework_ids').on('change', function(event) {
    const textRegex = /Blue Lava Security Program/
    const versionRegex = /\d/g

    let text = event.target.options[event.target.selectedIndex].innerHTML
    let cmm = text.match(textRegex);

    if (cmm != null) { // if framework selected is the CMM
      let version = parseInt(text.match(versionRegex).join(''))
      let versions = []

      event.target.options.forEach(function(option) {
        if (option.innerHTML.match(textRegex) !== null) {
          let digits = option.innerHTML.match(versionRegex)
          let semanticVersion = parseInt(digits.join(''))

          versions.push(semanticVersion)
        }
      })

      let maxVersion = Math.max(...versions)

      if (version < maxVersion) { // if the version is less than the max version available
        let container = $(event.target.parentElement)

        if (!container.find('.warning')[0]) {
          let title = 'Updated Version Available'
          let message = 'An updated version of the selected framework is available. You can use the selected older version, but it is not recommended.'
          let markup = `
          <div id="version-warning" class='modal-content'>
            <div class='warning'>
              <div class='content'>
                <h7 class='title'>${title}</h7>
                <p class='message'>${message}</p>
              </div>
            </div>
          </div>`

          container.append(markup)
        }
      } else {
        $('#version-warning').remove() // greatest vesrion of the CMM selected
      }
    } else {
      $('#version-warning').remove() // non-CMM selected
    }
  })

  $('#assessment_type_of').on('change', (event) => {
    const target = event.target
    const selectedValue = target.options[target.selectedIndex].value

    filterFrameworkSelect(selectedValue)
  })

  $('#assessment_assignments_page').on('change','#select-focus-areas', (event) => {
    const capability = event.target.value;
    const newUrl = new URL(window.location);

    newUrl.searchParams.set('highlight', capability);
    window.location.assign(newUrl);
  });

  // We need re-select the focus areas when adding users because the html
  // get replaced. So a custom event did the job to call this function outside of this file.
  $(document).on('highlight-focus-areas', () => {
    const selectedFocusArea = $('#select-focus-areas').val();
    if( selectedFocusArea && selectedFocusArea.length ) {
      highLightFocusAreas(selectedFocusArea);
    }
  })

  $('#assessment_assignments_page').on('click', '#massAssignToNotApplicable', (event) => {
    event.preventDefault()

    massAssignToNotApplicable();
  })

  $('#assessment_assignments_page').on('click', '#assigments-tabs li', (event) => {
    event.preventDefault()

    changedUserTabs(event);
  })

  $(document).on('user-changed-active-tab', () => {
    changedUserTabs();
  })

  $(document).trigger('highlight-focus-areas');
  $(document).trigger('user-changed-active-tab');
})

function highLightFocusAreas(focus_area_id) {
  $('.cloud-area').removeClass('highlight');
  $('.capability-container').removeClass('highlight');

  const capability = `tag-${focus_area_id}`;
  $(`.${capability}`).addClass('highlight');

  $('.capability-container').each((_index, container) => {
    const $container = $(container);
    const numCapabilities = $container.find('.capability').length;
    const numCapabilitiesHighlighted = $container.find(`.${capability}`).length;

    if (numCapabilities === numCapabilitiesHighlighted) {
      $container.addClass('highlight');
    }
  });
}

function massAssignToNotApplicable() {
  const notApplicableOnes = $('.tabs-panel.is-active .card-section.highlight-focus-areas .draggable.cloud-area:not(.highlight)') // select all capabilities not highlighted
  const notApplicableContainer = $('.droppable-target.not_applicable')
  $(notApplicableOnes).appendTo(notApplicableContainer)

  $('form#assessments_assignments').trigger('submit')
}

function changedUserTabs(){
  const notApplicablePanelId = 'assigment_tab_1'
  const activePanel = $('#assigments-tabs li.is-active')[0]
  const capabilitiesNotApplicable = $('.tabs-panel.is-active .card-section.highlight-focus-areas .draggable.cloud-area:not(.highlight)') // select all capabilities not highlighted
  if(capabilitiesNotApplicable.length <= 0 || notApplicablePanelId === $(activePanel).attr('id') ){
    $(".mass-assign-container").hide()
  }else{
    $(".mass-assign-container").show()
  }
}

function filterFrameworkSelect(selectedValue) {
  const frameworkSelect = document.getElementById('assessment_framework_ids')
  if (selectedValue == 'Foundational') {
    frameworkSelect.selectedIndex = null
    if( !($('*[data-supports-foundational="false"]').parent().is('span')) ) $('*[data-supports-foundational="false"]').wrap('<span>')
  } else {
    if( ($('*[data-supports-foundational="false"]').parent().is('span')) ) $('*[data-supports-foundational="false"]').unwrap()
  }
}

function disableCMMOnlyFields(){
  // disable attributes of for non-CMM frameworks on load
  const regex = /Blue Lava Security Program/;
  let text = $('#assessment_framework_ids').find(":selected").text()

  let matches = text.match(regex);
  let match = matches !== null

  $('#assessment_type_of').prop("disabled", !match);
  $('#assessment_evidence_enabled').prop("disabled", !match);
  $("#assessment_evidence_enabled").prop("checked", false);
}
