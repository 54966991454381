import { ColumnChooser, ExcelExport } from '@syncfusion/ej2-grids'
import { beforeTurboCache } from 'src/turbo'
import { EventsGridActionMenu } from 'src/dataGrid/eventsGridActionMenu'
import { BLGrid,
         defaultGridOptions,
         columnsFromTable,
         pluckTableData,
         extractIdFromLink } from 'src/dataGrid/common'


BLGrid.Inject(ColumnChooser, ExcelExport)

const gridId = 'events-datagrid'
const gridOptions = {
  toolbar: [
    { text: 'Search', align: 'left' },
    'ColumnChooser',
  ]
}

export const eventsGrid = {
  id: gridId,
  load: () => {
    const data = pluckTableData('#grid-fallback-table')
    const grid = new BLGrid({
      ...defaultGridOptions,
      ...gridOptions,
      columns: columnsFromTable(false),
      dataSource: data,
      showColumnChooser: true
    });

    grid.excelQueryCellInfo = (args) => {
      if(args.column.field === 'id') {
        args.value = extractIdFromLink(args.value)
      }
    }

    beforeTurboCache(() => grid.destroy())
    grid.appendTo(`#${gridId}`)

    const actionMenu = new EventsGridActionMenu(grid)
    actionMenu.render()
  }
}
