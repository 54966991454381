import { Grid, Sort, Filter, Toolbar, Resize, Page } from '@syncfusion/ej2-grids'
import unescape from 'lodash/unescape';
Grid.Inject(Sort, Filter, Toolbar, Resize, Page)

export const BLGrid = Grid

export function extractIdFromLink(linkPath) {
  if (linkPath) {
    return linkPath.match(/\d+/g)[1]
  }
  return linkPath
}

export function extractCopyFromLink(domString) {
  const virtualDocument = document.createRange().createContextualFragment(domString)
  const domNode = virtualDocument.children[0]
  return domNode && domNode.innerHTML || ''
}

export function extractHrefFromLink(linkPath) {
  const regex = /href="(.*?)"/g
  const href = linkPath.match(regex)
  if (href && href.length > 0) {
    return href[0].replace("href=", "").replaceAll("\"", "")
  }
  return linkPath
}

export function createLinkFromHref(linkPath) {
  const href = extractHrefFromLink(linkPath)

  return `${window.location.origin}${href}`
}

export function currentPath() {
  return window.location.pathname.replace(/\/+$/, '')
}

export function getGrid(id) {
  return document.getElementById(id).ej2_instances[0]
}


function exportQueryCellInfo (args) {
  if (['name', 'owner', 'area_name', 'discipline', 'capability', 'project_name', 'assessment_names'].includes(args.column.field)) {
    args.value = unescape(args.value);
  }
}

export const defaultGridOptions = {
  allowSorting: true,
  allowFiltering: true,
  allowResizing: true,
  allowGrouping: false,
  allowExcelExport: true,
  allowSelection: false,
  allowPaging: true,
  excelQueryCellInfo: exportQueryCellInfo,
  rowHeight: 45,
  filterSettings: { type: 'Menu' },
  pageSettings:  { pageSize: 50, pageSizes: ['All', 50, 100, 200] },
  height: 600,
  gridLines: 'Both'
}

export function columnsFromTable(allowSelection) {
  const columns = [...document.querySelectorAll('#grid-fallback-table th')].map(h => {
    let c = {
      field: h.dataset.field,
      headerText: h.textContent,
      width: h.dataset.width,
      allowGrouping: h.dataset.grouping == 'true',
      type: h.dataset.type,
      clipMode: 'EllipsisWithTooltip',
      // By default allow filtering
      allowFiltering: h.dataset.filtering == 'false' ? false : true,
      allowSorting: h.dataset.sortable == 'false' ? false : true,
      visible: h.dataset.visible == 'false' ? false : true
    }

    if(h.dataset.renderTemplateId) {
      c.template = `#${h.dataset.renderTemplateId}`
    }

    if (h.dataset.multiSelect == 'true') {
      c.filter =  { type: 'CheckBox' }
    }

    if (h.dataset.islink == 'true') {
      c.valueAccessor = (field, data, column) => {
        if (column.exporting) {
          if (column.field == 'id') {
            return createLinkFromHref(data[field])
          } else if(column.field === 'project_name' || column.field === 'top_risk') {
            return extractCopyFromLink(data[field])
          } else {
            return extractHrefFromLink(data[field])
          }
        } else {
          return data[field]
        }
      }

      c.disableHtmlEncode = false
    }

    if (c.field == 'id') {
      c.isPrimaryKey = true
    }

    if (h.dataset.type == 'datetime') {
      //Reference:  https://ej2.syncfusion.com/documentation/common/internationalization/
      c.format = 'MMM d, y hh:mm a z'
    }

    if (h.dataset.type == 'date') {
      c.sortComparer = sortDateComparer
      c.format = 'MMM dd, y'
    }

    if (h.dataset.type == 'html-element') {
      c.disableHtmlEncode = false
      c.type = "string"
    }

    if (h.dataset.type == 'actions') {
      c.disableHtmlEncode = false
      c.allowSorting = false
    }

    if (h.dataset.filterType == 'Menu') {
      c.filter = {
        type: 'Menu',
        operator: 'contains'
      }
      c.disableHtmlEncode = false
    }

    // This is the same thing as dataset.multiselect, but using filter type seems much clearer
    if (h.dataset.filterType === 'CheckBox') {
      c.filter = {
        type: 'CheckBox',
      }
    }

    if (h.dataset.sortType == 'date') {
      c.sortComparer = sortDateComparer
    }

    if (h.dataset.sortType == 'version') {
      c.sortComparer = sortVersionComparer
    }

    if (h.dataset.sortType == 'percentage') {
      c.sortComparer = sortPercentageComparer
    }

    return c
  })

  if (allowSelection) {
    columns.unshift(
      { type: 'checkbox', width: 50 }
    )
  }

  return columns
}

function sanitizedDate(date) {
  return date == '-' ? 0 : date
}

function sortDateComparer(a, b) {
  a = new Date(sanitizedDate(a))
  b = new Date(sanitizedDate(b))
  return a > b ? 1 : a < b ? -1 : 0
}

function sortVersionComparer(a, b) {
  a = versionFromHtmlElement(a)
  b = versionFromHtmlElement(b)
  return a > b ? 1 : a < b ? -1 : 0
}

// Assumes format of <label ... >x.x.x</label>
function versionFromHtmlElement(element) {
  return element.split(">")[1].split("</")[0].trim()
}

function sortPercentageComparer(a, b) {
  a = parseInt(percentageFromHtmlElement(a))
  b = parseInt(percentageFromHtmlElement(b))
  return a > b ? 1 : a < b ? -1 : 0
}

// Assumes the element is an HTML element of nested divs
// followed by a percentage, '2%'
function percentageFromHtmlElement(element) {
  return element.split(">").pop().slice(0,-1).trim()
}

export function pluckTableData(tableSelector) {
  return [...document.querySelector(tableSelector).querySelectorAll('tbody tr')]
          .map(tr => [...tr.querySelectorAll('td')])
          .map(row => row.reduce((memo, item) => {
            // explictly set column data type wasn't being inferred correctly by syncfusion
            // for filtering/sorting
            if (item.getAttribute('data-field') == 'assessments_count') {
              memo[item.getAttribute('data-field')] = parseInt(item.innerHTML)
            }
            else if(item.getAttribute('data-type') == 'date' ){
              memo[item.getAttribute('data-field')] = new Date(item.innerHTML)
            }
            else {
              memo[item.getAttribute('data-field')] = item.innerHTML
            }

            return memo
          }, {}))
}

export function toggleGrouping(grid) {
  grid.allowGrouping = !grid.allowGrouping
}

export function csvExport(grid) {
  grid.columns.forEach(c => c.exporting = true)
  grid.csvExport({fileName: `${currentPath().split('/').pop()}.csv`})
}

export function clearFilters(grid, hiddenColumns, visibleColumns, counterElementId, dropdownName) {
  grid.pageSettings = { pageSize: 50 }
  grid.searchSettings.key = ''
  grid.hideColumns(hiddenColumns.map((c) => c.headerText))
  grid.reorderColumnByTargetIndex(visibleColumns.map((c) => c.field).reverse(), 1) // Number 1 is the index where I want the columns to start positioning
  grid.showColumns(visibleColumns.map((c) => c.headerText))
  grid.clearGrouping()
  grid.clearFiltering()
  grid.clearRowSelection()
  grid.clearSelection()
  grid.clearSorting()

  if (dropdownName) $(`#${dropdownName}`).foundation('close')

  if(counterElementId) {
    const selectedCountElem = grid.element.querySelector(`#${counterElementId}`)
    selectedCountElem.innerHTML = ''
  }
}

  // NOTE:
  // THIS METHOD IS MEANT TO BE USED WHEN THE GRID IS IN GROUPED MODE.
  // IN GROUPED MODE, ALL RECORDS ARE SHOWN IN A SINGLE PAGE.
  // grid.getSelectedRecords will get all records that have been selected
  // between pages.  getSelectedRows will only get records that have been
  // selected on the current page anda returns DOM nodes.
  export function currentPageCheckedFindingIds(grid) {
    return grid.getSelectedRows().map(r => r.dataset.id)
  }

  // NOTE: Clicking the selectAll checkbox will select all the findings on
  // the page, but will not check the checkboxes if they are in a closed
  // grouping. So here, we will grab the finding records based on the ones
  // that are actually checked. I hate this.
  export function selectedGroupedFindings(grid) {
    const checkedFindings = currentPageCheckedFindingIds(grid)
    return grid.getSelectedRecords().filter(r => checkedFindings.includes(extractIdFromLink(r.id)))
  }
