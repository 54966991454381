import { TreeGrid, Sort, Filter, Toolbar, Resize, Page } from '@syncfusion/ej2-treegrid'
import { onTurboLoad, beforeTurboCache } from 'src/turbo'
import { defaultGridOptions } from 'src/dataGrid/common'

TreeGrid.Inject(Sort, Filter, Toolbar, Resize, Page)

const gridOptions = {
  toolbar: [{ text: 'Search', align: 'left' }, 'ExpandAll', 'CollapseAll'],
  dataBound: () => {
    // The following two lines are necessary for the dropdown to work after the TreeGrid renders
    Foundation.reInit($('.syncfusion-foundation-grid')) // This toggle elements off
    Foundation.reInit($('.syncfusion-foundation-grid')) // This toggle elements on
  }
}
// We need it so the grid date field can be sorted properly. If we dont use a real Date object
// it will be sorted as a String.
const assignDateToOrgArea = function(orgAreaObject) {
    orgAreaObject.created_at = new Date(orgAreaObject.created_at)
    if(orgAreaObject.children){
      orgAreaObject.children.forEach(function(orgArea) {
        assignDateToOrgArea(orgArea)
      })
    }
}

onTurboLoad(() => {
  const treeId = 'organizational-areas-tree-datagrid'
  const treeElement = document.getElementById(treeId)
  if (treeElement == null)
    return

  const rawJson = treeElement.dataset.organizationalAreaTreeData
  const data = JSON.parse(rawJson)
  data.forEach(assignDateToOrgArea)


  let treeGrid = new TreeGrid(Object.assign(
    {},
    defaultGridOptions,
    gridOptions,
    {
      dataSource: data,
      childMapping: 'children',
      columns: [
        { field: 'name', headerText: 'Name', width: 100, template: '#linktemplate' },
        { field: 'created_at', headerText: 'Created At', width: 20, type: 'date', format: 'MMM dd, y' },
        { field: 'actions', headerText: 'Actions', width: 40, disableHtmlEncode: false, allowSorting: false, allowFiltering: false, template: '#actiontemplate' }
      ]
    }
  ))

  treeGrid.toolbarClick = (args) => {
    if (args['item'].text === 'Excel Export')
      treeGrid.excelExport()
  }

  beforeTurboCache(() => treeGrid.destroy())

  treeGrid.appendTo(`#${treeId}`)
})
