import jQuery from 'jquery'
import { extractIdFromLink } from 'src/dataGrid/common'

const modalId = "findings-bulk-update";
const noFindingsMessageId = "bulk-findings-empty-message"
const formId = "new_findings_bulk_update_form";
const findingsCountMessageId = "findings-bulk-update-findings-count";
const errorMessagesId = "findings-bulk-update-errors";
const statusMap = {
  open: 'Open',
  in_progress: 'In Progress',
  remediated: 'Remediated',
  risk_accepted: 'Risk Accepted',
  ignored: 'Ignored',
  not_applicable: 'Not Applicable',
}

const inputIds = {
  findingIds: 'findings_bulk_update_form_finding_ids',
  status: 'findings_bulk_update_form_status',
  ownerId: 'findings_bulk_update_form_owner_id',
  priority: 'findings_bulk_update_form_priority',
}

export class FindingsBulkUpdateForm {
  constructor() {
    this.form = document.getElementById(formId)
    this.formSubmit = this.form.querySelector('input[type="submit"]')
    this.errorMessages = document.getElementById(errorMessagesId)
    this.disableFormSubmit()
    this.addFormTargetListener()
    this.bindBulkDeleteResponseHandler()
  }

  openForm(findings, grid) {
    this.grid = grid
    this.findings = findings
    this.cleanupInputs()
    this.setFindingIdsInput(findings)

    const formNode = document.getElementById(formId)
    const emptyFindingsMessageNode = document.getElementById(noFindingsMessageId)

    if (findings.length == 0) {
      formNode.classList.add('hidden');
      emptyFindingsMessageNode.classList.remove('hidden');
    } else {
      const pluralizedNoun = findings.length == 1 ? 'finding' : 'findings'
      const msg = `You have selected ${findings.length} ${pluralizedNoun}.`

      document.getElementById(findingsCountMessageId).innerHTML = msg

      emptyFindingsMessageNode.classList.add('hidden');
      formNode.classList.remove('hidden');
    }
    jQuery(`#${modalId}`).foundation('toggle')
  }

  setFindingIdsInput(findings) {
    const csvFindingIds = findings.map(r => extractIdFromLink(r.id)).join(',')
    document.getElementById(inputIds['findingIds']).value = csvFindingIds
  }

  addFormTargetListener() {
    if (!this.form) return

    this.form.addEventListener('change', () => {
      const findingIds = this.form.querySelector(`#${inputIds.findingIds}`).value
      const status = this.form.querySelector(`#${inputIds.status}`).value
      const ownerId = this.form.querySelector(`#${inputIds.ownerId}`).value
      const priority = this.form.querySelector(`#${inputIds.priority}`).value

      if (findingIds && (status || ownerId || priority)) {
        this.enableFormSubmit()
      } else {
        this.disableFormSubmit()
      }
    })
  }

  enableFormSubmit() {
    this.formSubmit.removeAttribute('disabled')
  }

  disableFormSubmit() {
    this.formSubmit.setAttribute('disabled', 'disabled')
  }

  bindBulkDeleteResponseHandler() {
    jQuery(`#${formId}`).on('ajax:success', function() {
      const status = this.form.querySelector(`#${inputIds.status}`).value
      const ownerId = this.form.querySelector(`#${inputIds.ownerId}`).value
      const priority = this.form.querySelector(`#${inputIds.priority}`).value
      const userMap = JSON.parse(document.querySelector('meta[name=user-map]').content)

      // NOTE: This is so dumb.
      // grid.getSelectedRecords()  will fetch all the records that have been selected (but maybe not checked because of lazy loading the UI.
      // this.grid.getSelectedRowIndexes() will get the indices of each of the selected records
      // We will build a collection we can reference to fetch the indices so we can update the rendered table.
      // Format:
      // { <findingId>: rowIndex, .... }
      let selectedRecordIndicesMap= {}
      const selectedRowIndexes = this.grid.getSelectedRowIndexes()
      this.grid.getSelectedRecords().forEach((record, index) => {
        selectedRecordIndicesMap[extractIdFromLink(record.id)] = selectedRowIndexes[index]
      })

      this.findings.forEach((finding) => {
        const findingId = extractIdFromLink(finding.id)
        const rowIndex = selectedRecordIndicesMap[findingId]

        if(status) finding.status = statusMap[status]
        if(priority) finding.priority = priority
        if(ownerId) finding.owner = userMap[ownerId]

        this.grid.editModule.updateRow(rowIndex, finding)
      })
      jQuery(`#${modalId}`).foundation('close')
    }.bind(this))

    jQuery(document).on('ajax:error', `#${formId}`, (e, xhrRespoonse) => {
      console.error('There was an error updating the findings.', JSON.stringify(arguments))
      const errorObject = xhrRespoonse.responseJSON.errors

      const errorMessages = Object.keys(errorObject).map((key) => {
        return errorObject[key].map((errorMessage) => {
          return `${key.split('_').join(' ')} ${errorMessage}`;
        }).join('<br/>')
      }).join('<br/>')

      this.errorMessages.innerHTML = errorMessages
    })
  }

  cleanupInputs() {
    this.errorMessages.innerHTML = ''
    Object.keys(inputIds).forEach((key) => {
      document.getElementById(inputIds[key]).value = ''
    })
  }
}
