import Chart_controller from "../charts/chart_controller";

export default class extends Chart_controller {
    // extend parent controller so we can use js functions in the callbacks instead of just plain json objects
    connect() {
        super.connect()
        this.element
        this.chart
        this.options
        this.defaultOptions
    }

    get defaultOptions() {
        return {
            responsive: true,
            maintainAspectRatio: true,
            // responsiveAnimationDuration: 0,
            // Just nest the legend: object inside of plugins: for Chart.js 3+ compatibility
            legend: {
                display: false
            },
            tooltips: {
                enabled: true,
                callbacks: {
                    label: function (tooltipItems) {
                        return tooltipItems.value + "%";
                    }
                }
            },
            layout: {
                padding: {
                    left: 280
                }
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    ticks: {
                        display: false
                    },
                    gridLines: {
                        display: false
                    }
                }],
                yAxes: [{
                    stacked: true,
                    ticks: {
                        mirror: true,
                        padding: 280,
                        //Make sure lines are same height as bars so that they are not hidden prematurely
                        lineHeight: this.chartData.labels.length > 11 ? 1 : 1.2,
                        //Shrink font size so we can fit more labels per chart
                        fontSize: this.chartData.labels.length > 11 ? 10 : 12,
                        callback: (value) => {
                            if (value.length > 46) {
                                return value.substring(0, 46) + "..."
                            }
                            return value;
                        }
                    },
                    gridLines: {
                        display: false
                    }
                }]
            }
        }
    }
}


